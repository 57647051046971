<template>
  <div class="courses innerpage">
    <HeaderInnerpage></HeaderInnerpage>
    <v-container class="my-6 innerpage-container">
      <v-row v-if="!isUserBasic">
        <v-col cols="12">
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
       <v-row v-if="isUserBasic">
        <v-col cols="12">
          <div>
            <h2 class="h2">Courses</h2>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!isUserBasic">
        <v-col md="4">
          <h1>Courses</h1>
        </v-col>
        <v-col offset-md="4" md="4" align="right">
          <v-btn color="cora-chip-color-green" dark rounded @click="addItem">
            + create new course
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isLoaded">
        <v-col cols="12" sm="12" md="12" lg="10">
        <template v-if="courses.length > 0" transition="fade-transition">
          <template v-if="isUserBasic">
            <v-col cols="12" v-for="item in courses" :key="item.id">
              <CourseItem :item="item" hideActions="false" />
            </v-col>
          </template>
          <template v-else>
            <v-col v-for="item in courses" :key="item.id">
              <v-card class="mx-auto courses-card" max-width="344" outlined>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="overline mb-4 header-card">
                      <b>{{ item.category.name }}</b>
                    </div>
                    <v-list-item-title class="text-h5 mb-1 title-card">
                      {{ item.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="text-card"
                      v-html="item.summary"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <span class="group">
                    <v-icon class="pa-2" @click="editItem(item)">
                      mdi-playlist-edit
                    </v-icon>
                    <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                  </span>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    :to="`/plans/${item.id}`"
                    rounded
                    color="secondary"
                    class="mt-5"
                    >Enter course</v-btn
                  >
                </v-list-item>
                <v-divider class="my-3"></v-divider>
                <v-list-item>
                  <v-btn
                    outlined
                    rounded
                    text
                    dark
                    class="grey darken-2 mr-2"
                    @click="openUrl('coursechapters', item.id)"
                  >
                    View Chapters
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    text
                    dark
                    class="grey darken-2"
                    @click="openUrl('chapterquizzes', item.id)"
                  >
                    View Quizzes
                  </v-btn>
                </v-list-item>
              </v-card>
            </v-col>
          </template>
        </template>
        <template v-else>
          <v-alert border="top" colored-borderv elevation="5"
            >There are no courses available.
          </v-alert>
        </template>
        </v-col>
      </v-row>
      <v-row v-else>
        <!-- loading -->
        <v-col cols="12">
          <p class="text-center text-overline mb-8">Loading Courses</p>
          <v-progress-linear
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" right top>
        {{ alert }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <h3 class="text-h4">{{ formTitle }}</h3>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    filled
                    v-model="editedItem.title"
                    label="Title*"
                    :rules="[v => !!v || 'title is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="editedItem.summary"
                    label="Summary*"
                    :rules="[v => !!v || 'Summary is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="editedItem.duration"
                    label="Duration*"
                    :rules="[durationRules]"
                    required
                  ></v-text-field>
                  <v-select
                    filled
                    v-model="editedItem.category"
                    :items="categories"
                    item-text="name"
                    :menu-props="{ maxHeight: '400' }"
                    label="Category*"
                    :rules="[v => !!v || 'Category is required']"
                    return-object
                    required
                  ></v-select>
                  <v-file-input
                    filled
                    v-model="editedItem.image"
                    accept="image/*"
                    label="Upload Image"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="reset">
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="saveItem(editedItem)"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderInnerpage from "@/components/HeaderInnerpage";
import CourseItem from "@/components/CourseItem";
import CoursesService from "../services/courses.service";
import { Role } from "@/helpers/roles";

export default {
  components: {
    HeaderInnerpage,
    CourseItem
  },
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/"
      },
      {
        text: "Courses",
        disabled: true,
        href: "/mycourses"
      }
    ],
    durationRules: v => {
      if (!v) return "Duration is required";
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
      return 'Number has to be between 0 and 999';
    },
    snackbar: false,
    alert: "",
    courses: [],
    originalItem: {
      title: "",
      summary: "",
      duration: "",
      category: "",
      image: null
    },
    defaultItem: {
      title: null,
      summary: null,
      duration: null,
      category: null,
      image: null
    },
    valid: true,
    editedItem: {
      title: "",
      summary: "",
      duration: "",
      category: "",
      image: null
    },
    editedIndex: -1,
    dialog: false,
    courseCategories: [
      "Film and Television Industry",
      "Planning and Business Continuity"
    ],
    categories: [],
    apiError: "",
    isLoaded: false
  }),

  computed: {
    isOrgAdmin() {
      let orgAdmin = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.OrgAdmin) {
          orgAdmin = true;
        }
      });
      return orgAdmin;
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },

    isUserBasic() {
      let userBasic = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserBasic) {
          userBasic = true;
        }
      });
      return userBasic;
    },

    custom() {
      return this.isAdminEditor ? 0 : 1;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    orgId() {
      if (this.isAdminEditor)
        return this.$store.state.auth.user.user.organizations.id;
      return 0;
    },

    formTitle: {
      get() {
        return this.editedIndex === -1 ? "Create New Course" : "Edit Course";
      },
      set(newTitle) {
        return newTitle;
      }
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.initialize();
      this.setCategoriesList();
    }
  },

  methods: {
    async initialize() {
      try {
        let response;
        if (this.isAdminEditor) {
          response = await CoursesService.getAllCourses();
          this.courses = response.data.data.slice();
        } else {
          response = await CoursesService.get({
            orgId: this.orgId
          });

          this.courses = response.data.slice();
        }

        this.isLoaded = true;
      } catch (e) {
        console.log("error getting courses", e);
        this.isLoaded = true;
      }
    },
    openUrl(linkName, courseId) {
      window.location.href = "/" + linkName + "/" + courseId;
    },
    editItem(item) {
      this.formTitle = "Edit Course";
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.courses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    addItem() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    async saveItem(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          //let data;
          let data = new FormData();

          if (this.isAdminEditor) {
            // update item
            // data = {
            //   id: item.id,
            //   title: item.title,
            //   summary: item.summary,
            //   duration: item.duration,
            //   category_id: item.category_id,
            //   image: item.image
            // };

            data.append("id", item.id);
            data.append("title", item.title);
            data.append("summary", item.summary);
            data.append("duration", item.duration);
            data.append("category_id", item.category_id);
            data.append("image", item.image);
          } else {
            // update item
            // data = {
            //   id: item.id,
            //   title: item.title,
            //   summary: item.summary,
            //   duration: item.duration,
            //   category_id: item.category_id,
            //   image: item.image,
            //   custom: this.custom,
            //   organization_id: this.orgId
            // };

            data.append("id", item.id);
            data.append("title", item.title);
            data.append("summary", item.summary);
            data.append("duration", item.duration);
            data.append("category_id", item.category_id);
            data.append("image", item.image);
            data.append("custom", this.custom);
            data.append("organization_id", this.orgId);

          }

          const response = await CoursesService.update({
            data: data
          });
          this.editedItem = response.data;
          //this.courses[this.editedIndex] = this.editedItem;
          this.courses.splice(this.editedIndex, 1, this.editedItem);

          this.showSnackbar("The Course has been updated successfully");
        } else {
          // create item

          //let data;
          let data = new FormData();

          if (this.isAdminEditor) {
            // update item
            // data = {
            //   title: item.title,
            //   summary: item.summary,
            //   duration: item.duration,
            //   category: item.category.name,
            //   category_id: item.category.id,
            //   image: item.image
            // };
            data.append("title", item.title);
            data.append("summary", item.summary);
            data.append("duration", item.duration);
            data.append("category", item.category.name);
            data.append("category_id", item.category.id);
            data.append("image", item.image);
          } else {
            // update item
            // data = {
            //   title: item.title,
            //   summary: item.summary,
            //   duration: item.duration,
            //   category: item.category.name,
            //   category_id: item.category.id,
            //   image: item.image,
            //   custom: this.custom,
            //   organization_id: this.orgId
            // };

            data.append("title", item.title);
            data.append("summary", item.summary);
            data.append("duration", item.duration);
            data.append("category", item.category.name);
            data.append("category_id", item.category.id);
            data.append("image", item.image);
            data.append("custom", this.custom);
            data.append("organization_id", this.orgId);
          }

          const response = await CoursesService.create({
            data: data
          });

          this.courses.push(response.data);

          
          this.showSnackbar("The Course has been added successfully");
        }
        this.reset();
        this.dialog = false;

      }
    },
    async deleteItem(item) {
      const index = this.courses.indexOf(item);
      confirm("Are you sure you want to delete this course?") &&
        this.courses.splice(index, 1);

      await CoursesService.remove({
        id: item.id
      });

      this.showSnackbar(item.title + " has been deleted");
    },
    async setCategoriesList() {
      try {
        const response = await CoursesService.getCategories();
        this.categories = response.data.data;
      } catch (e) {
        console.log("error getting categories", e);
      }
    },
    showSnackbar(msg) {
      this.alert = msg;
      this.snackbar = true;
    },
    reset() {
      this.$refs.form.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.col {
  flex-grow: 0;
}
.courses-card {
  padding-bottom: 10px;
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
